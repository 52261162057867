import React, { useState } from 'react';
import './App.css';
import ImageSlider from "./ImageSlider";
import ExpandedImageView from "./ExpandedImageView";
import './styles.css';

function App() {
  const images = [
    'https://images.pexels.com/photos/1402787/pexels-photo-1402787.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
    ,'https://images.pexels.com/photos/1213447/pexels-photo-1213447.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
    ,'https://images.pexels.com/photos/1749303/pexels-photo-1749303.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
    ,'https://images.pexels.com/photos/3075993/pexels-photo-3075993.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
    ];
    const imageContent=[
      'LIor is awesome', 'Lior is the best'
    ]
    
  return (
    <div className='root'>
      
      <ImageSlider
          images = {images}
         />
        </div>
  );
}

export default App;
