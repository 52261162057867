import React , {useState, useEffect,useRef} from 'react';


function ImageSlider({images}) {
  
    const trackRef = useRef(null);
    const imageRefs = useRef(images.map(() => React.createRef()));
    const [mouseDownAt, setMouseDownAt] = useState(0);
    const [sliderPosition, setSliderPosition] = useState(0)
    const [prevSliderPosition, setPrevSliderPosition] = useState(0);
    const [isMouseDown, setIsMouseDown] = useState(false);
    const [expandedImage, setExpandedImage] = useState(null);

    const getClientX = (e) => {
      if (e.touches) {
        // touch event
        return e.touches[0].clientX;
      } else {
        // mouse event
        return e.clientX;
      }
  };
    const handleImageClick = (index) => {
      if (expandedImage === index) {
        setExpandedImage(null); // If the image is already expanded, unexpand it
      } else {
        setExpandedImage(index); // Otherwise, expand the clicked image
      }
    };

    
    const handleDown = (e) =>{
      setIsMouseDown(true);
      setMouseDownAt(getClientX(e));

    }

    const handleUp = (e) => {
      setIsMouseDown(false);
      setPrevSliderPosition(sliderPosition);
    };

    const handleMove = (e) => {
      if (!isMouseDown) return;

      const mouseDelta = mouseDownAt - getClientX(e);
      const maxDelta = window.innerWidth / 2;
      const percentage = (mouseDelta / maxDelta) * -100;  
      const nextPosition = prevSliderPosition + percentage;  
      const clampedPosition = Math.max(Math.min(nextPosition, 0), -80);  
      setSliderPosition(clampedPosition);
    }

    useEffect(() => {
      if (trackRef.current) {
        trackRef.current.style.transform = `translate(${sliderPosition}%, -50%)`;
        trackRef.current.animate({
          transform: `translate(${sliderPosition}%, -50%)`,
        }, { duration: 1200, fill: "forwards" });
      }
  
      imageRefs.current.forEach(ref => {
        if (ref.current) {
          ref.current.style.objectPosition = `${sliderPosition + 100}% 50%`;
          ref.current.animate({
            objectPosition: `${100 + sliderPosition}% center`,
          }, { duration: 1200, fill: "forwards" });
        }
      });
    }, [sliderPosition]); // Re-run the effect whenever `sliderPosition` changes

    useEffect(() =>{
      window.addEventListener('mouseup', handleUp);
      window.addEventListener('mousemove',handleMove);
      
      window.addEventListener('touchend', handleUp);
      window.addEventListener('touchstart', handleDown);
      window.addEventListener('touchmove', handleMove);


      return() => {
        window.removeEventListener('touchend', handleUp);
        window.removeEventListener('mouseup', handleUp);
        window.removeEventListener('mousemove',handleMove);
      }
    }, [handleUp,handleMove]);

    return (
      <div 
      className='image-track'
      ref={trackRef}
      onMouseDown={handleDown}
      onTouchStart={handleDown}
      style={{ transform: `translateX(${sliderPosition}%) translateY(-50%)`}}
      >
        {images.map((url, index) =>(
          <img 
          className={`image ${expandedImage === index ? 'expanded' : ''}`} key={index} src ={url} alt='' draggable='false' ref={imageRefs.current[index] } onClick={() => handleImageClick(index)} />
        ))}
      </div>
    );
  }
  
  export default ImageSlider;
